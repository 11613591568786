const translateEn={
    // HomePage section
    DitTittle:"Digits Infotech Software Solutions",
    ElevateYourBusinesswith:"Elevate Your Business with " ,
    CustomizedSoftwareSolutions:"Customized Software Solutions.",
    HomepagePara:"Empowering Businesses with Innovative Software Solutions. Building Tomorrow's Success Today",
    Getintouch:" Get in touch",
    WatchNowIntro:"Watch Now Intro",
    
    // Register Form 
    RegisterYourAppointment:"Register Your Appointment",
    WeHeretoHelpYou:"We here to help you 24/7 with experts",


    //whatwedo
    whatwedo:"What we do ?",
    whatwedoContent:"Start working with Digits InfoTech that can provide everything you need to generate awareness, drive traffic, connect",
    // items in what we do
    whatwedoheading1:"High Performance",
    whatwedodesc1:"Optimizated software significantly improved performance for clients.",
    whatwedoheading2:"Best Securities",
    whatwedodesc2:"The robust security measures implemented in softwares to protect sensitive data.",    
    whatwedoheading3:"Trusted Service",
    whatwedodesc3:"Committed to delivering exceptional service and building long-term relationships with clients.",
    whatwedoheading4:"Info Technology",
    whatwedodesc4:"We expertise in the latest information technology trends and advancements.",
    whatwedoheading5:"24/7 Support",
    whatwedodesc5:"Round-the-clock support services, quick response and issue resolution. Through various support channels.",
    whatwedoheading6:"IT Management",
    whatwedodesc6:"Software solutions streamline IT management processes for businesses.",
    whatwedoheading7:"Certified Company",
    whatwedodesc7:"Have certifications, industry affiliations, and partnerships from expertise and quality.",
    whatwedoheading8:"Data Analytics",
    whatwedodesc8:"Helps businesses gain valuable insights and make data-driven decisions.",


    // About Our Story

    AboutOurStory:"About Our Story",
    About1:"Start working witsh Digits Infotech is a leading software development company that is dedicated to creating cutting-edge software solutions to help businesses thrive in the digital era. With a team of highly skilled software engineers, designers, and developers, we specialize in developing innovative and customized software applications that meet the unique needs of our clients across various industries.",
    About2:"Our company is built on a foundation of technical expertise, creativity, and a customer-centric approach. We believe that software has the power to transform businesses and society, and we are passionate about leveraging the latest technologies and industry best practices to deliver software solutions that drive tangible results."
    

     // dd more translations

}


export default translateEn;