const translateTam={
    // HomePage section
    DitTittle:"Digits Infotech Software Solutions",
    ElevateYourBusinesswith:"உங்கள் வணிகத்தை உயர்த்துங்கள்" ,
    CustomizedSoftwareSolutions:"தனிப்பயனாக்கப்பட்ட மென்பொருள் தீர்வுகள்.",
    HomepagePara:"புதுமையான மென்பொருள் தீர்வுகளுடன் வணிகங்களை மேம்படுத்துதல். நாளைய வெற்றியை இன்று உருவாக்குங்கள்",
    Getintouch:"தொடர்பில் இருங்கள்",
    WatchNowIntro:"இப்போது அறிமுகத்தைப் பாருங்கள்",
    
    // Register Form 
    RegisterYourAppointment:"உங்கள் சந்திப்பை பதிவு செய்யவும்",
    WeHeretoHelpYou:"நிபுணர்களுடன் 24/7 உங்களுக்கு உதவ நாங்கள் இங்கே இருக்கிறோம்",
    

    // what we do
    whatwedo:"நாம் என்ன செய்கிறோம்?",
    whatwedoContent:"டிஜிட்ஸ் இன்ஃபோடெக் உடன் பணிபுரியத் தொடங்குங்கள், இது விழிப்புணர்வை உருவாக்க, போக்குவரத்தை இயக்க, இணைக்க உங்களுக்குத் தேவையான அனைத்தையும் வழங்குகிறது.",
     // items in what we do
     whatwedoheading1:"உயர் செயல்திறன்",
     whatwedodesc1:"உகந்த மென்பொருள் வாடிக்கையாளர்களின் செயல்திறனை கணிசமாக மேம்படுத்தியது.",
     whatwedoheading2:"சிறந்த பாதுகாப்பு",
     whatwedodesc2:"முக்கியமான தரவைப் பாதுகாப்பதற்காக மென்பொருளில் செயல்படுத்தப்பட்ட வலுவான பாதுகாப்பு நடவடிக்கைகள்.",    
     whatwedoheading3:"நம்பகமான சேவை",
     whatwedodesc3:"விதிவிலக்கான சேவையை வழங்குவதற்கும் வாடிக்கையாளர்களுடன் நீண்ட கால உறவுகளை உருவாக்குவதற்கும் உறுதிபூண்டுள்ளது.",
     whatwedoheading4:"தகவல் தொழில்நுட்பம்",
     whatwedodesc4:"சமீபத்திய தகவல் தொழில்நுட்ப போக்குகள் மற்றும் முன்னேற்றங்களில் நாங்கள் நிபுணத்துவம் பெற்றுள்ளோம்.",
     whatwedoheading5:"24/7 ஆதரவு",
     whatwedodesc5:"ரவுண்ட்-தி-க்ளாக் ஆதரவு சேவைகள், விரைவான பதில் மற்றும் சிக்கல் தீர்வு. பல்வேறு ஆதரவு சேனல்கள் மூலம்.",
     whatwedoheading6:"தகவல் தொழில்நுட்ப மேலாண்மை",
     whatwedodesc6:"மென்பொருள் தீர்வுகள் வணிகங்களுக்கான IT மேலாண்மை செயல்முறைகளை நெறிப்படுத்துகின்றன.",
     whatwedoheading7:"சான்றளிக்கப்பட்ட நிறுவனம்",
     whatwedodesc7:"நிபுணத்துவம் மற்றும் தரத்தில் இருந்து சான்றிதழ்கள், தொழில்துறை இணைப்புகள் மற்றும் கூட்டாண்மை ஆகியவற்றைக் கொண்டிருக்க வேண்டும்.",
     whatwedoheading8:"தரவு பகுப்பாய்வு",
     whatwedodesc8:"வணிகங்கள் மதிப்புமிக்க நுண்ணறிவுகளைப் பெறவும் தரவு சார்ந்த முடிவுகளை எடுக்கவும் உதவுகிறது.",

     // dd more translations
}


export default translateTam;