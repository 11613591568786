import i18n from 'i18next';
import {initReactI18next} from 'react-i18next'

import translateEn from './locals/en';
import translateTam from './locals/tam';


i18n.use(initReactI18next)
.init({
    resources:{
        en:{
            translation:translateEn,
        },
        tam:{
            translation:translateTam,
        },
    },
    fallbackLng:'en',
    interpolation:{
        escapeValue:false,
    },
})


export default i18n