import React from "react";

// Root Include
// const Root = React.lazy(() => import("./pages/Home/indexRoot"));
const Root = React.lazy(() => import("./pages/ITSolution/index"));

//Main Index
const Main = React.lazy(() => import("./pages/Home/indexMain"));

//Special
// const PageComingSoon = React.lazy(() =>
//   import("./pages/Pages/Special/PageComingSoon")
// );
// const PageComingSoon2 = React.lazy(() =>
//   import("./pages/Pages/Special/PageComingSoon2")
// );
 
const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));
// const PageThankYou = React.lazy(() =>
//   import("./pages/Pages/Special/PageThankYou")
// );
// const PageMaintenance = React.lazy(() =>
//   import("./pages/Pages/Special/PageMaintenance")
// );

//Auth Pages
/*
const PageLogin = React.lazy(() => import("./pages/Pages/AuthPages/PageLogin"));
const PageCoverLogin = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageCoverLogin")
);
const PageLoginThree = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageLoginThree")
);

const PageSignup = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageSignup")
);
const PageCoverSignup = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageCoverSignup")
);
const PageSignupThree = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageSignupThree")
);

const PageCoverRePassword = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageCoverRePassword")
);
const PageRecoveryPassword = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageRecoveryPassword")
);
const PageRePasswordThree = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageRePasswordThree")
);
*/
const FBPolicy = React.lazy(() =>
  import("./pages/Job/fb-privacy-policy")
);


/*
To add a page into site, 
1. import components and 
2. add it into the routes, 
3. have the page in pages folder, 
4. link the page in nav (topbar.js)
5. copy the path from router and set it into Topbar- navLinks
*/

// Import all components


//const Software = React.lazy(() => import("./pages/Software/index"));

const ITSolution = React.lazy(() => import("./pages/ITSolution/index"));
 //const iTSolutionTwo = React.lazy(() => import('./pages/ITSolutionTwo/index'));

const PageAboutUs = React.lazy(() => import("./pages/Pages/PageAboutUs"));
const PageServices = React.lazy(() => import("./pages/Pages/PageServices"));
const PageContactOne = React.lazy(() => import("./pages/Pages/Contact/PageContactOne"));

const WebDevelopment = React.lazy(() => import("./pages/ITSolution/ITServices/WebDev"));
const MobileDevelopment = React.lazy(() => import("./pages/ITSolution/ITServices/MobileDev"));
const DigitalMarketing = React.lazy(() => import("./pages/ITSolution/ITServices/DigitalMarket"));
const GraphicDesigning = React.lazy(() => import("./pages/ITSolution/ITServices/GraphicDes"));


//const Portfolio = React.lazy(() => import("./pages/Portfolio/index"));

//src\pages\Pages\survey-form.js
const SruveryForm = React.lazy(() => import("./pages/Pages/survey-form.js"));
const PageJobDetail = React.lazy(() =>  import("./pages/Pages/Careers/PageJobDetail"));
const PageJob = React.lazy(() => import("./pages/Pages/Careers/PageJob"));
const PageJobApply = React.lazy(() =>  import("./pages/Pages/Careers/PageJobApply"));

// 
const routes = [
  //routes without Layout

  //Contct without layout
  
  //special pages
  { path: "/page-error", component: PageError, isWithoutLayout: true },

  // Landings

   //{ path: "/index-software", component: Software, isTopbarDark: true  },
   { path: "/index", component: ITSolution, isTopbarDark: true  },
   { path: "/index-it-solution", component: ITSolution, isTopbarDark: true  },
   //{ path: "/index-it-solution-two", component: iTSolutionTwo, isTopbarDark: true  },

   { path: "/page-aboutus", component: PageAboutUs, isTopbarDark: true },
   { path: "/page-contact-one", component: PageContactOne, isTopbarDark: true },
   { path: "/page-services", component: PageServices, isTopbarDark: true },

   { path: "/web-development", component: WebDevelopment, isTopbarDark: true },
   { path: "/mobile-development", component: MobileDevelopment, isTopbarDark: true },   
   { path: "/digital-marketing", component: DigitalMarketing, isTopbarDark: true },
   { path: "/graphic-design", component: GraphicDesigning, isTopbarDark: true },


   //{ path: "/index-portfolio", component: Portfolio, isTopbarDark: true },

   
   { path: "/tellus", component: SruveryForm, isTopbarDark: true },
   { path: "/page-job", component: PageJob, isTopbarDark: true },
   { path: "/page-jobs", component: PageJob, isTopbarDark: true },
   { path: "/page-job-apply", component: PageJobApply, isTopbarDark: true },
   { path: "/page-job-detail", component: PageJobDetail, isTopbarDark: true },
   { path: "/fb-policy", component: FBPolicy, isTopbarDark: true },
   { path: "/page-terms", component: FBPolicy, isTopbarDark: true },
   { path: "/page-privacy", component: FBPolicy, isTopbarDark: true },

   

  //Index Main
  

  //Index root
  { path: "/", component: Root, isWithoutLayout: false, exact: true },
  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
