
import React, { Component } from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";
//import i18n from "i18next";
//Import Switcher CSS
class ThemeSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSwitchToggle: false,
    };
    this.toggleSwitcher = this.toggleSwitcher.bind(this);
  }
  toggleSwitcher = () => {
    this.setState({ isSwitchToggle: !this.state.isSwitchToggle });
  };
  handelLanguageChange(lan) {
    i18n.changeLanguage(lan)
  }
  componentDidMount(){
    setTimeout(()=>{
      this.setState({isSwitchToggle:true})
    },5000)
    setTimeout(()=>{
      this.setState({isSwitchToggle:false})
    },10000)
  }
  render() {
    return (
      <React.Fragment>
        <div
          id="style-switcher"
          style={{ left: this.state.isSwitchToggle ? "0px" : "-189px" }}
          className="bg-light border p-3 pt-2 pb-2"
        >

          <h3 className="title text-center pt-3 mb-0">Select Language</h3>
          <Link onClick={() => {
            this.handelLanguageChange('tam')
          }}
            className={i18n.language=="tam" ? "btn btn-sm w-100 btn-primary mt-2 " : "btn btn-sm w-100 btn-light mt-2 " }
            to="/"
          >
            Tamil</Link>
          <Link onClick={() => {
            this.handelLanguageChange('en')
          }}
          className={i18n.language=="en" ? "btn btn-sm w-100 btn-primary mt-2 " : "btn btn-sm w-100 btn-light mt-2 " }
          to="/"
          >
            English</Link>
          <div className="bottom">
            <Link
              to=""
              onClick={this.toggleSwitcher}
              className="settings bg-white shadow d-block"
            >
              <i className="mdi mdi-cog ms-1 mdi-24px position-absolute mdi-spin text-primary"></i>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ThemeSwitcher;
