import React, { Component, Suspense } from "react";
import { useLocation } from "react-router-dom";

import Tagline from "./Tagline";
import ThemeSwitcher from "./ThemeSwitcher";
import FeatherIcon from "feather-icons-react";

const Topbar = React.lazy(() => import("./Topbar"));
const NavbarPage = React.lazy(() => import("../../pages/Saas Onepage/NavbarPage"));
const Footer = React.lazy(() => import("./Footer"));
const FooterWithoutMenuLightSocialOnly = React.lazy(() => import("./FooterWithoutMenuLightSocialOnly"));

const CustomDot = () => (
  <React.Fragment>
    <FeatherIcon icon="arrow-up" className="icons" />
  </React.Fragment>
);

const CryptoTagline = () => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-12">
        <div className="text-slider">
          {/* CryptoTagline content */}
        </div>
      </div>
    </div>
  </div>
);

const Loader = () => (
  <div id="preloader">
    <div id="status">
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    </div>
  </div>
);

// Convert class component to function component to use hooks
function Layout({ children, hasDarkTopBar }) {
  const location = useLocation();

  const tagLineContent = location.pathname === "/index-crypto-two" ? <Tagline><CryptoTagline /></Tagline> : null;

  return (
    <React.Fragment>
      <Suspense fallback={<Loader />}>
        {location.pathname === "/index-onepage" ? (
          <NavbarPage />
        ) : (
          <Topbar tagline={tagLineContent} hasDarkTopBar={hasDarkTopBar} />
        )}

        {children}
        {(() => {
          if (
            location.pathname === "/index-marketing" ||
            location.pathname === "/index-digital-agency" ||
            location.pathname === "/index-modern-business" ||
            location.pathname === "/index-services" ||
            location.pathname === "/index-job" ||
            location.pathname === "index-freelancer"
          ) {
            return <Footer isLight={true} />;
          } else if (
            location.pathname === "/index-portfolio"
          ) {
            return <FooterWithoutMenuLightSocialOnly className="border-0 bg-light text-dark" />;
          } else if (
            location.pathname === "/index-personal" ||
            location.pathname === "/helpcenter-overview" ||
            location.pathname === '/helpcenter-guides' ||
            location.pathname === '/helpcenter-support-request' ||
            location.pathname === "/page-invoice"
          ) {
            return <FooterWithoutMenuLightSocialOnly className="" />;
          } else {
            return <Footer />;
          }
        })()}

        <ThemeSwitcher />
      </Suspense>
    </React.Fragment>
  );
}

export default Layout;
